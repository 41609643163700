#yareto {
    height: 2em;
}

#meneks {
    height: 2em;
}

a {
    color: #4e7de9;
}

.full-height {
    min-height: calc(100vh - 245px);
}

@media only screen and (max-width: 845px) {
    #yareto {
        height: 1em;
    }

    #meneks {
        height: 1em;
    }
}
body {
    color: #333;
    background-color: #ebf2ff;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
h1 {
    font-size: 3em;
    margin-bottom: 0.3em;
}

.tiny-space {
    margin-top: -0.3em;
}

h2 {
    text-align: center;
}

strong {
    font-size: 1.3em;
    font-family: "Arial Rounded MT Bold", sans-serif;
    color: #4e7de9;
}

.stronger {
    font-size: 1.5em;
    font-family: "Arial Rounded MT Bold", sans-serif;
    color: #4e7de9;
}

.textbox {
    width: 40em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
}

.text {
    width: 25em;
    text-align: left;
    margin-top: -2em;
}

#logo {
    width: 25em;
}

#portrait {
    width: 25em;
    margin-left: 5em;
}

#incar {
    width: 25em;
    margin-right: 5em;
}

.logo_heading {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5em;
    margin-bottom: 5em;
}

@media only screen and (max-width: 845px) {

    /* For mobile phones: */

    .text {
        width: 80%;
        margin-top: -2em;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .textbox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.3em;
    }

    .flexbox {
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    #logo {
        width: 10em;
    }

    #portrait {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    #incar {
        width: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3em;
    }

    #bewertung {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }
}
.textbox {
    width: 42em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.ulDienstleistungen {
    list-style-type: none;
    line-height: 200%;
    margin-bottom: 4em;
}

.ulDienstleistungen li {
    color: white;
    margin-bottom: 1em;
    padding: 1em;
    border: 0.1em solid #ccc;
    background-color: #4e7de9;
}

#kfzInnung {
    margin-left: 3em;
    height: 16em;
}

#motorhaube {
    margin-left: 3em;
    height: 16em;
}

.full-height {
    min-height: calc(100vh - 245px);
}

@media only screen and (max-width: 845px) {
    .flex {
        display: flex;
        flex-direction: column;
    }

    #kfzInnung {
        height: 10em;
        margin-left: auto;
        margin-right: auto;
    }

    #motorhaube {
        height: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
    }

    .textbox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}
#yareto {
    height: 2em;
}

#meneks {
    height: 2em;
}

a {
    color: #4e7de9;
}

.full-height {
    min-height: calc(100vh - 245px);
}

@media only screen and (max-width: 845px) {
    #yareto {
        height: 1em;
    }

    #meneks {
        height: 1em;
    }
}
/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
    width: 100%;
}

/* Style the links inside the navigation bar */
.topnav div {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 1em 1em;
    text-decoration: none;
    font-size: 1em;
    cursor: pointer;
}

/* Change the color of links on hover */
.topnav div:hover {
    background-color: #ddd;
    color: black;
}

/* Add a color to the active/current link */
.topnav div.active {
    background-color: #4e7de9;
    color: white;
}

#mobile {
    position: 'absolute';
    top: 0;
    left: 0; 
    height: 1.5em;
}

@media only screen and (max-width: 845px) {
    .topnav {
        display: flex;
        flex-direction: column;
        background-color: #333;
        overflow: hidden;
        width: 100%;
    }

    /* Style the links inside the navigation bar */
    .topnav div {
        text-align: left;
        font-size: 0.8em;
    }
}
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: #333;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.footer div {
    color: #f2f2f2;
}

.impressum {
    font-size: x-small;
    margin-top: auto;
    margin-bottom: auto;
}

.kontakt {
    margin-top: auto;
    margin-bottom: auto;
}

.link {
    font-size: 1.2em;
    padding: 1em;
    background-color: #4e7de9;
    color: white;
    text-align: center;
    text-decoration: none;
}

#logo_footer {
    width: 15em;
    margin-top: auto;
    margin-bottom: auto;
}

@media only screen and (max-width: 845px) {
    .footer {
        display: flex;
        flex-direction: column;
    }

    .impressum {
        font-size: x-small;
        margin-left: 2em;
    }

    .kontakt {
        margin-top: 2em;
        margin-left: 1em;
    }

    .link {
        font-size: 0.8em;
    }

    #logo_footer {
        width: 10em;
        margin-top: 2em;
        margin-left: 2em;
    }
}
.reviewCard {
    color: white;
    margin-bottom: 1em;
    padding: 1em;
    border: 0.1em solid #ccc;
    background-color: #4e7de9;
}

.flexbox2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    margin-top: 2em;
}

.text2 {
    width: 16em;
    text-align: left;
    margin-top: 1em;
}

#bewertung {
    width: 16em;
    margin-left: 5em;
}

#smalllink {
    color: white;
}

@media only screen and (max-width: 845px) {

    /* For mobile phones: */

    .text {
        width: 80%;
        margin-top: -2em;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .textbox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.3em;
    }

    .flexbox {
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    #logo {
        width: 10em;
    }

    #portrait {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    #incar {
        width: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3em;
    }

    #bewertung {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }
}

.reviewCard {
    color: white;
    margin-bottom: 1em;
    padding: 1em;
    border: 0.1em solid #ccc;
    background-color: #4e7de9;
}

.flexbox2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
    margin-top: 2em;
}

.text2 {
    width: 16em;
    text-align: left;
    margin-top: 1em;
}

#bewertung {
    width: 16em;
    margin-left: 5em;
}

#smalllink {
    color: white;
}

@media only screen and (max-width: 845px) {

    /* For mobile phones: */

    .text {
        width: 80%;
        margin-top: -2em;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .textbox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.3em;
    }

    .flexbox {
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    #logo {
        width: 10em;
    }

    #portrait {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    #incar {
        width: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3em;
    }

    #bewertung {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }
}
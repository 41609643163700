h1 {
    font-size: 3em;
    margin-bottom: 0.3em;
}

.tiny-space {
    margin-top: -0.3em;
}

h2 {
    text-align: center;
}

strong {
    font-size: 1.3em;
    font-family: "Arial Rounded MT Bold", sans-serif;
    color: #4e7de9;
}

.stronger {
    font-size: 1.5em;
    font-family: "Arial Rounded MT Bold", sans-serif;
    color: #4e7de9;
}

.textbox {
    width: 40em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5em;
}

.text {
    width: 25em;
    text-align: left;
    margin-top: -2em;
}

#logo {
    width: 25em;
}

#portrait {
    width: 25em;
    margin-left: 5em;
}

#incar {
    width: 25em;
    margin-right: 5em;
}

.logo_heading {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5em;
    margin-bottom: 5em;
}

@media only screen and (max-width: 845px) {

    /* For mobile phones: */

    .text {
        width: 80%;
        margin-top: -2em;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .textbox {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        font-size: 2em;
        margin-bottom: 0.3em;
    }

    .flexbox {
        width: 80%;
        display: flex;
        flex-direction: column;
    }

    #logo {
        width: 10em;
    }

    #portrait {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }

    #incar {
        width: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3em;
    }

    #bewertung {
        width: 10em;
        margin-top: 1em;
        margin-left: auto;
        margin-right: auto;
    }
}
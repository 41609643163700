/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
    width: 100%;
}

/* Style the links inside the navigation bar */
.topnav div {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 1em 1em;
    text-decoration: none;
    font-size: 1em;
    cursor: pointer;
}

/* Change the color of links on hover */
.topnav div:hover {
    background-color: #ddd;
    color: black;
}

/* Add a color to the active/current link */
.topnav div.active {
    background-color: #4e7de9;
    color: white;
}

#mobile {
    position: 'absolute';
    top: 0;
    left: 0; 
    height: 1.5em;
}

@media only screen and (max-width: 845px) {
    .topnav {
        display: flex;
        flex-direction: column;
        background-color: #333;
        overflow: hidden;
        width: 100%;
    }

    /* Style the links inside the navigation bar */
    .topnav div {
        text-align: left;
        font-size: 0.8em;
    }
}